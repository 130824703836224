<template>
    <div class="message-text">
        <div class="open-chat">
            <div class="noti-title">
                <img class="m-r-8" src="@/assets/images/icons/hello.png" width="24px" height="24px" />
                <span>매칭권 1회</span>
            </div>
            <div class="noti-body">
                <span class="pre-price" style="text-decoration: line-through"
                    >{{ ((selectOnboardPlan.pre_price * 10) / 11).toLocaleString() }}원</span
                >
                → {{ ((selectOnboardPlan.price * 10) / 11).toLocaleString() }}원
                <br />
                (사전 예약 할인)
            </div>
            <div class="noti-blue-box">
                <div class="noti-time">만남 보장 100% 정책</div>
                <div>내가 마음에 드는 사람과 실제 만남이 성사되지 않으면 100% 환불(카드 결제 취소) 해드립니다.</div>
            </div>
            <!-- <div class="noti-body">
                <p class="m-b-16">
                    기다리시는 동안 매칭 선호 사항을 먼저 작성해주세요! 이상형 파악에 큰 도움이 됩니다.
                </p>
                <BottomButton
                    @click="onClickMatchPrefer"
                    label="매칭 선호사항 입력하기"
                    :black-btn="true"
                    :non-fixed="true"
                />
            </div> -->
        </div>
        <BottomButton @click="onClickBuyProduct" label="안심 결제하기" :non-fixed="true" :greyBtn="true" />
    </div>
</template>

<script>
export default {
    name: 'ActionIntroduceProduct',
    props: ['message'],
    data: () => ({
        stats: [],
    }),
    computed: {
        actionOpenChatNonPro() {
            return this.$translate('ACTION_OPEN_CHAT_NON_PRO').replace(/%s/, this.chat.user.name)
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        isFaq() {
            const chatUser = this.chat.user

            return chatUser.id === 2
        },
        selectOnboardPlan() {
            if (this.$store.getters.me.gender === 0) {
                return this.$store.getters.products.all.find(item => item.code === 'prepaid_male_1')
            } else {
                return this.$store.getters.products.all.find(item => item.code === 'prepaid_female_1')
            }
        },
    },
    methods: {
        onClickBuyProduct() {
            this.$store.commit('setSelectedProduct', this.selectOnboardPlan)
            console.log(this.$store.getters.products.all)
            this.$router.push({
                name: 'TicketPaymentMethodsPage',
                // params: { categoryName: this.category.category },
            })
        },
    },
}
</script>
<style scoped lang="scss">
.open-chat {
    padding: 9px 4px;
    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 16px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
    .noti-blue-box {
        margin-top: 8px;
        background: #f0f8ff;
        border-radius: 8px;
        color: #111111;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 24px;
        .noti-time {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
        }
    }

    ::v-deep .bottom-button > button {
        height: 40px !important;
    }
}
</style>
